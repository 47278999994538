<template>
        <div style="margin:0 auto">
            <Form ref="ycwjQueryForm" :model="formInline" :rules="ruleInline" label-position="right" :label-width="100" style="margin-bottom:20px;border:1px solid lightgray;background:lightblue;">
                <table width="100%">
                    <tr>
                        <td align="right" width="23%">
                            <FormItem  label="车辆型号:" prop="parentid" style="margin-bottom:5px;margin-top:10px">
                                <i-select v-model="formInline.parentid">
                                    <i-option value=null key="0">请先选择车型</i-option>
                                    <i-option v-if="modellist !=null" v-for="(item,index) in modellist" :value="item.id" :key="item.id">车型{{item.modelno}}</i-option>
                                </i-select>
                            </FormItem>
                        </td>
                        <td align="right" width="23%">
                            <FormItem  label="选择年份:" prop="madeyear" style="margin-bottom:5px;margin-top:10px">
                                <i-select v-model="formInline.madeyear">
                                    <i-option value=null key="0">请选择年份</i-option>
                                    <i-option v-if="modellist !=null" v-for="item in [yearnow()-1, yearnow()]" :value="item" :key="item">{{item}}年</i-option>
                                </i-select>
                            </FormItem>
                        </td>
                        <td align="left">
                            <div style="display: inline-flex;justify-items: center;padding-left: 20px;">
                                <Button type="primary" @click="handleQuery()" >查询</Button>    
                                <Button  @click="handleReset()" style="margin-left:10px">重置</Button>
                            </div>
                        </td>
                    </tr>
                </table>
            </Form>

            <div v-if="scjhdetail.length >0" style="margin:20px auto;padding: 30px;">
                <div style="height:40px;float: right;">
                    <Button type="warning" @click="onpreview">打印预览</Button>
                    <Button type="primary" @click="onprint" style="margin-left: 15px;">直接打印</Button>
                </div> 
                <scjhview id="printdiv"  v-bind:scdetail="scjhdetail"/>
            </div>
        </div>
    </template>
    
    <script>
    import ScjhPrint from '@/views/business/workshop/checkdept/purchase/ScjhPrint'
    import {getPrinters,public_print,public_print_with_dir,public_preview_with_dir} from '@/zsjs/zskjprinter.js'
    export default {
        name:'Scjh',
        data(){
            return {
                logoninfo: this._self.$root.$store.state.LogonInfo,
                companyid: this._self.$root.$store.state.LogonInfo.companyid,
                modellist:this._self.$root.$store.state.ModelListInfo,
                formInline:{
                    parentid:null,
                    madeyear:null
                },
                showmadeyear: null,
                ruleInline: {
                    parentid: [
                        { required: true, message: '请选择车型', trigger: 'blur' },
                    ],
                    madeyear:[
                        { required: true, message: '请选择年份', trigger: 'blur' },
                    ],
                },
                scjhdetail:[]
            }
        },
        mounted(){
            //this.handleQuery();
        },
        methods:{
            getmodelnobyid(id){
                for(let i=0;i<this.modellist.length;i++){
                    if(this.modellist[i].id == id){
                        return this.modellist[i].modelno
                    }
                }
                return null
            },
            handleQuery(){
                if(this.formInline.parentid == null){
                    this.$Message.error({content:'请先选择车型！',duration:3})
                    return
                }
    
                if(this.formInline.madeyear == null){
                    this.$Message.error({content:'请先选择年份！',duration:3})
                    return
                }
                let postdata={
                    companyid: this.companyid,
                    modelno: this.getmodelnobyid(this.formInline.parentid),
                    madeyear: this.formInline.madeyear
                }
    
                this.$axios({
                    method:'post',
                    url:'/api/checkdept/doc/scjh/list',
                    data: postdata,
                    headers:{'Content-Type': 'application/x-www-form-urlencoded'},
                    transformRequest: function(obj) {
                        var str = [];
                        for(var p in obj){
                            str.push(encodeURIComponent(p) + "=" + encodeURIComponent(obj[p]));
                        }
                        return str.join("&");
                    }
                }).then(function(res){
                    if(res.data.resultCode ==0){
                        console.log(res)
                        this.scjhdetail = res.data.data
                        console.log(this.scjhdetail)
                        this.$Message.success({content:res.data.msg, duration:3})
                        this.showmadeyear = this.formInline.madeyear
                    }
                    else{
                        this.$Message.error({content:res.data.data.msg, duration:3})
                    }
                }.bind(this));  
            },
            handleReset(){
                this.handleQuery()
            },
            onpreview(){
                public_preview_with_dir("printdiv",1,2);
            },
            onprint(){
                public_print_with_dir("printdiv",1,2);
            },
            yearnow(){
                return new Date().getFullYear()
            }
        },
        components:{
            'scjhview':ScjhPrint
        }
    }
    </script>
    <style scoped>
    </style>
    