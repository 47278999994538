<!-- 合格证台账打印功能-->
<template>
<div id="printdiv" style="margin:0 auto;margin-top:1px;clear: both;">
    <table  style="width: 100%;height:5%;word-wrap: break-word; word-break: break-all;" >
        <tr>
            <td width="25%">
            </td>
            <td width="50%" style="text-align: center;">
                <h2>生产计划</h2>
            </td>
            <td width="25%">
                {{modelcfg.companycode}}-CX-013-01
            </td>
        </tr>
        <tr style="font-size: 12px;">
            <td>部门：生产部</td>
        </tr>
    </table>
    <table id="tbl"  border="1px solid black" cellspacing="0"  style="margin:0 auto;width:100%;height:95%"  >
        <tr style="height: 30px;font-size: 16px;font-weight: bold;font-family: '楷体';padding-left: 4px;" >
            <th align="left">序号</th>
            <th align="center" valign="center" >类别</th>
            <th align="center" valign="center" >型号</th>
            <th align="center" valign="center" >要求（数量及其他）</th>
            <th align="center" valign="center" >操作时间</th>
            <th align="center" valign="center" >交付时间</th>
            <th align="center" valign="center" >交付工序（部门）</th>
            <th align="center" valign="center" >审批</th>
            <th align="center" valign="center" >备注</th>
        </tr>
        <tr v-for="(item,index) in scdetail" style="height: 20px;font-size: 12px;font-family: '楷体';padding-left: 4px;">
            <td >{{index+1}}</td>
            <td >电动自行车</td>
            <td >{{item.modelno}}</td>
            <td >标准/{{item.count}}辆</td>
            <td >{{getlastdate(item.madeyear,item.mademonth)}}</td>
            <td >{{getlastdate(item.madeyear,item.mademonth)}}</td>
            <td >仓库</td>
            <td >{{modelcfg.fileapprover}}</td>
            <td ></td>
        </tr>
        <tr v-for="index in (28-scdetail.length)" style="height: 20px;font-size: 12px;font-family: '楷体';padding-left: 4px;">
            <td ></td>
            <td ></td>
            <td ></td>
            <td ></td>
            <td ></td>
            <td ></td>
            <td ></td>
            <td ></td>
            <td ></td>
        </tr>
    </table>
</div>
</template>
 <script>
    import {getPrinters,public_print,public_print_with_dir} from '@/zsjs/zskjprinter.js'
    import * as sysTool from '@/zsjs/systemTool.js'
    export default {
        name:'ScjhPrint',
        props:['scdetail'],
        data(){
            return {
                modelcfg:this._self.$root.$store.state.ModelCfg
            }
        },
        mounted(){

        },
        methods:{
            getpreDate(maodate){
                return sysTool.getNextDate(maodate,-1)
            },
            formatDate(date){
                if(date !=null)
                    return  (new Date(date)).Format('yyyy-MM-dd')
                else
                    return ''    
            },
            getlastdate(y,m){
                let ldate = new Date(y, m, 0)
                console.log(m)
                console.log(ldate)
              return  ldate.Format('yyyy-MM-dd');
            },
            printdata(){
                public_print_with_dir("printdiv",1,2);
            },
        },
        mounted(){
        },
        watch:{
            scdetail(n){
                console.log(this.cgdetail)
            },
            modelcfg (n){
                console.log(this.modelcfg)
            }
        }
    }
</script>

<style type="text/css">
.printtd{
    padding-top:5px;
    padding-bottom:5px;
    height:40px;
}

#tbl {
    
}

#tbl  th {
    font-size: 16px;
    font-weight: bold;
}
.tbltd{
    font-size: 12px;
    font-family: "楷体";
    padding-left: 4px;
    height: 20px;
}
</style>